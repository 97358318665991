import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import ThirstyCactus from './abis/ThirstyCactus.json'

class App extends Component {

  ethEnabled = async () => {
    this.setState({isError: false, errorMessage: ''})
    if(typeof window.ethereum !== 'undefined') {
      try {
        const accounts =  await window.ethereum.request({ method: 'eth_requestAccounts' })

        if (accounts) {
          this.setState({ account: accounts[0]})
          const web3 = new Web3(Web3.givenProvider)
          const networkId = await web3.eth.net.getId()
          const networkData = ThirstyCactus.networks[networkId]
          if (networkData) {
            const itemABI = ThirstyCactus.itemABI
            const itemAddress = networkData.itemAddress
            const itemContract = new web3.eth.Contract(itemABI, itemAddress)
            this.setState({ itemContract })

            const shopABI = ThirstyCactus.shopABI
            const shopAddress = networkData.shopAddress
            const shopContract = new web3.eth.Contract(shopABI, shopAddress)
            this.setState({ shopContract })

            const totalSupply = (await this.state.itemContract.methods.itemGroups(1).call()).mintCount
            this.setState({ totalSupply })
          } else {
            this.setState({statusUpdate: 'Error'})
            this.setState({isError: true, errorMessage: 'Smart contract not deployed to detected network'})
          }
        } else {
          this.setState({statusUpdate: 'Error'})
          this.setState({isError: true, errorMessage: 'There are no accounts in your wallet.'})
        }
      } catch (error) {
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: 'There was an error connecting to your wallet'})
      }
    } else {
      this.setState({statusUpdate: 'Error'})
      this.setState({isError: true, errorMessage: 'No ETH Web3 wallet was detected'})
    }
  }

  // Determine which pool should be minted from.
  mint = (numberToMint) => {
    const currentURL = window.location.href
    let urlPath = currentURL.substring(currentURL.lastIndexOf('/') + 1);
    const idMapping = {
      'xCPQVxYiu17g4AOvaq': 6,
    };
    const poolId = parseInt(idMapping[urlPath]);
console.log(poolId);

    this.setState({ statusUpdate: 'Confirm Transaction...' })
    this.setState({ isError: false, errorMessage: '' })
    numberToMint = parseInt(numberToMint)
    if (numberToMint <= 0) {
      this.setState({isError: true, errorMessage: 'You did not enter a quantity to mint.'})
    } else if (numberToMint <= 20) {
      let calculatedCost = numberToMint *  15000000000000000
      if (poolId === 0 || poolId === 1 || poolId === 3 || poolId === 4 || poolId === 5) {
        calculatedCost = 0
      }
      this.state.shopContract.methods.mintFromPool(poolId, 1, 0, numberToMint).send({
        from: this.state.account,
        value: calculatedCost
      })
      .on('transactionHash', (hash) => {
        this.setState({statusUpdate: 'Minting Started...'})
      })
      .on('receipt', (receipt) => {
        this.setState({statusUpdate: 'Transaction Receipt Received, Check Wallet for Final Confirmation'})
        this.getNumberMinted()
      })
      .on('error', (error, receipt) => {
        this.setState({statusUpdate: 'Error'})
        this.setState({isError: true, errorMessage: 'There was an error with the transaction'})
      })
    } else {
      this.setState({statusUpdate: 'Error'})
      this.setState({isError: true, errorMessage: 'You tried minting more than the allowed 20'})
    }
  }

  getNumberMinted = async () => {
    try {
      const totalSupply = (await this.state.itemContract.methods.itemGroups(1).call()).mintCount
      this.setState({ totalSupply })
    } catch  (error)  {
      console.log('Contract Call Did not work')
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      contract: null,
      totalSupply: 0,
      isError: false,
      errorMessage: '',
      statusUpdate: 'Wallet Connected'
    }
  }

  render() {
    return (
      <div className="App">
          <div className='header'>
            <a href="https://www.thristycactus.com" className="text-block-2 brand w-nav-brand">
              THIRSTY CACTUS
            </a>
          </div>
        <div className="body">
          <div className="left">
            <h3>Mint Your Cactus</h3>
            <br/>


            { this.state.account &&
              <div><span className="text-block-2">STATUS: {this.state.statusUpdate}</span></div>
            }
            { this.state.isError &&
                <div className="error">{ this.state.errorMessage }</div>
            }
<br/><br/>
            { !this.state.account &&
                <form onSubmit={(event) => {
                  event.preventDefault()
                  this.ethEnabled()
                }}>
                  <input
                    type='submit'
                    className="button connect-button metamask"
                    value="CONNECT WALLET"
                  />
                </form>
              }
              { this.state.account &&
                <form onSubmit={(event) => {
                  event.preventDefault()
                  const numberToMint = this.numberToMint.value
                  // this.componentDidMount(numberToMint)
                  this.mint(numberToMint)
                }}>
                  <h3>0.015 ETH</h3>
                  <h4>Max 20 per transaction</h4><br/> <br/>
                  <span className="quantity">Quantity:</span>
                  <input
                    type="number"
                    defaultValue="1"
                    min="1" max="20"
                    ref={(input) => { this.numberToMint = input}}
                  /><br/>

                  <input
                    type='submit'
                    className="button mint-button w-button mint"
                    value="MINT"
                  />
                </form>
              }
            <br/>
            <br/> <h3>{ this.state.account && 'Total Minted ' + this.state.totalSupply + '/11111'}</h3>

          </div>
        </div>
      </div>
    )
  };
}

export default App;
